import * as Sentry from "@sentry/gatsby";

const environment = process.env.GATSBY_ENV || process.env.NODE_ENV;

if (environment !== "development") {
    Sentry.init({
        dsn: "https://796a0be8c45a440e90439a6a7039da0f@o1176445.ingest.sentry.io/6327175",
        integrations: [
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
                maskAllInputs: false
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}
